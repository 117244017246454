import React from 'react'
import { Link } from 'react-router-dom'
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
} from '@material-ui/core'

export default ({ title, description, icon, href }) => (
  <Card>
    <CardActionArea component={props => <Link {...props}/>} to={href}>
      {icon && (
        <CardMedia
          image={icon}
          title={title}
        />
      )}
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {title}
        </Typography>
        {description && (
          <Typography variant="body2" color="textSecondary" component="p">
            {description}
          </Typography>
        )}
      </CardContent>
    </CardActionArea>
  </Card>
)