import { createMuiTheme } from '@material-ui/core/styles';
import DefaultTheme from './defaultTheme';

export default createMuiTheme({
  ...DefaultTheme,
  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  palette: {
    primary: {
      main: '#2b87aa'
    },
    secondary: {
      main: '#8a0f14'
    }
  },
});