import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom'
import Header from '../../Components/Header/Header'
import SshKey from '../SshKey';
import Home from '../Home';
import Box from '@material-ui/core/Box';


function App() {
  return (
    <Box
      mt={8}
    >
      <Router>
        <Header />
        <Switch>
          <Route path="/sshkey/:urllogin?">
            <SshKey />
          </Route>

          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </Box>
  );
}

export default App;
