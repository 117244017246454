import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Switch from '@material-ui/core/Switch';


export default function ThemeSwitcher() {
  const checked = useSelector(state => state.app.darkTheme);
  const dispatch = useDispatch();

  return (
    <Switch
      checked={checked}
      onChange={evt => dispatch({ type: 'switchTheme' })}
    />
  );
}