import React from 'react';
import { Link } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import ThemeSwitcher from '../ThemeSwitcher'

export default () => {
  console.log('render header');
  return (
    <AppBar>
      <Toolbar>
        <Link to="/">
          <Typography color="initial" variant="h6">
            Examples
          </Typography>
        </Link>
        <Box ml="auto">
          <ThemeSwitcher/>
        </Box>
      </Toolbar>
    </AppBar>
  )
}