import React from 'react'
import {
  Box,
  Container
 } from '@material-ui/core';

import { SshKey } from '../../Components/Widgets'

export default () => (
  <Container maxWidth="sm">
    <Box pt={2}/>

    <Box display="flex" justifyContent="space-between">
      <Box mx="auto" width="48%"><SshKey/></Box>
    </Box>
  </Container>
)