import React from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';

import white from './white';
import dark from './dark';

const themes = {
  dark,
  white,
}

export default function Provider({children}) {
  const darkTheme = useSelector(state => state.app.darkTheme);
  const theme = {...themes[darkTheme ? 'dark' : 'white']};
  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
}