import React from 'react'
import DefaultWidget from '../default'
import icon from './image.png'

export default () => (
  <DefaultWidget
    title="Получить ssh ключ"
    description="Функция позволяет получить публичный ключ по имени пользователя github"
    icon={icon}
    href="/sshkey"
  />
)