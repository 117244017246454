import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Container from '@material-ui/core/Container'
import { Typography, TextField } from '@material-ui/core'

export default () => {
  const { urllogin } = useParams();
  const [login, setlogin] = useState(urllogin ?? '');
  const [key, setkey] = useState(false);
  const history = useHistory();

  const getKey = async () => {
    const response = await fetch(`https://api.github.com/users/${login}/keys`);
    const data = await response.json();
    setkey(data[0]?.key);
    history.replace('/sshkey/' + login);
  }

  const clear = () => {
    setkey(false);
    setlogin('');
  }

  return (
    <Container
      maxWidth="sm"
    >
      <Box pt={2}/>

      <Card variant="outlined">
        <CardContent>
          <Typography variant="h5">
            Получить публичный ключ ssh
          </Typography>

          <Box mt={1}/>

          <TextField
            fullWidth
            onChange={evt => setlogin(evt.target.value)}
            value={login}
            label="Логин на github"
          />

          {key && (
            <>
              <Box mt={1}/>
              <TextField
                fullWidth
                multiline={true}
                rowsMax={5}
                variant={'outlined'}
                value={key}
                inputProps={{spellcheck: false, readonly: true}}
              />
            </>
          )}
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => getKey()}
          >
            Запросить
          </Button>

          <Button variant="outlined" color="secondary" onClick={clear}>Очистить</Button>
        </CardActions>
      </Card>
    </Container>
  )
}