const initialState = {
  darkTheme: false
}

export default function(state = initialState, action) {
  switch(action.type) {
    case 'switchTheme':
      return {
        ...state,
        darkTheme: !state.darkTheme
      }

    case 'test':
      return {
        ...state,
        test: Math.random()
      }

    default:
      return state;
  }
}