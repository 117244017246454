import { createStore, combineReducers } from 'redux';

import app from './app';

const store = createStore(
  combineReducers({app})
);

store.subscribe(() => console.log(store.getState()))

export default store;
